<template>
  <div class="enterprise">
    <BannerImg :img-url="$StaticFileUrl + '/mp/BannerImage/Enterprise_banner_2.png'">
      <div class="title wow fadeInDown" data-wow-duration="1s">企业费控一站式解决方案</div>
      <div class="desc wow fadeInDown" data-wow-duration="1s">预算关联、流程驱动、数据集成、全程数字化</div>
      <div class="flex-row flex-col-center">
        <div class="btn-red wow flipInX" data-wow-duration="1s" style="margin-top: 100px">立即咨询</div>
        <div class="btn-red-linear wow flipInX" data-wow-duration="1s" style="margin-top: 100px">免费试用</div>
      </div>
    </BannerImg>
  </div>
</template>

<script>
import WOW from 'wowjs'
import BannerVideo from '@/components/BannerVideo'

/**
 * @author  XuHongli
 * @date  2022/12/28 10:12
 * @version 1.0
 * @description
 */
import BannerImg from '@/components/BannerImg'
export default {
  name: 'Enterprise',
  components: {
    BannerImg,
  },
  mounted() {
    new WOW.WOW().init()
  }
}
</script>

<style scoped>

</style>
